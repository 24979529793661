<template>
  <div class="allowance-config-modal">
    <b-modal
      ref="allowance-config-modal"
      title="Cấu hình phụ cấu"
      hide-footer
      size="xl"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group class="required-control">
            <label>Tên cấu hình:</label>
            <b-form-input
              size="sm"
              v-model="allowanceConfigModel.name"
              placeholder="Nhập tên cấu hình"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group class="required-control">
            <label>Gói phụ cấp:</label>
            <BInputNumber
              size="sm"
              v-model="allowanceConfigModel.amount"
              placeholder="Nhập gói phụ cấp"
              class="text-right"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group class="required-control">
            <label>Bộ phận:</label>
            <b-form-select
              size="sm"
              class="select-style"
              v-model="allowanceConfigModel.departmentId"
              :options="listDepartment"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group class="required-control">
            <label>Trạng thái hoạt động:</label>
            <b-form-select
              size="sm"
              class="select-style"
              v-model="allowanceConfigModel.isActive"
              :options="[
                {
                  id: 1,
                  name: 'Hoạt động',
                },
                {
                  id: 0,
                  name: 'Ngừng hoạt động',
                },
              ]"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group class="required-control">
            <label>Chức vụ</label>
            <b-input-group>
              <vue-multi-select
                v-model="allowanceConfigModel.jobTitles"
                :options="jobTitles"
                placeholder="Chọn chức vụ"
                option-label="name"
              ></vue-multi-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group class="required-control">
            <label>Danh sách tiêu chí</label>
            <b-input-group>
              <vue-multi-select
                v-model="allowanceConfigModel.criterias"
                :options="criterias"
                placeholder="Chọn tiêu chí"
                option-label="name"
              ></vue-multi-select>
            </b-input-group>
          </b-form-group>
          <b-table
            :items="allowanceConfigModel.criteriaItems"
            :fields="criteriaFields"
            hover
            bordered
          >
            <template v-slot:cell(rate)="row">
              <BInputNumber
                size="sm"
                v-model="row.item.rate"
                placeholder="Nhập tỉ trọng"
                class="text-right"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-button
        style="fontweight: 600; width: 100px"
        variant="primary"
        size="sm"
        @click="onSave()"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal()"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>
<style lang="scss">
.allowance-config-modal {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
}
</style>
<style>
.multi-select-wrapper[data-v-1983ee16] {
  width: 100% !important;
}
</style>
<script>
import { makeToastFaile, makeToastSuccess, cloneDeep } from '@/utils/common';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import ApiService from '@/core/services/api.service';
import VueMultiSelect from 'vue-simple-multi-select';
import { JOB_TITLE, ALLOWANCE_CRITERIA, TIME_TRIGGER } from '@/utils/constants';

export default {
  data() {
    return {
      allowanceConfigModel: {
        id: null,
        name: '',
        departmentId: 1,
        amount: 0,
        jobTitles: [],
        criterias: [],
        criteriaItems: [],
        isActive: 1,
      },
      listDepartment: [],
      jobTitles: [],
      criterias: [],
      criteriaFields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tiêu chí',
          thStyle: { fontWeight: 600, color: '#181c32', width: '50%' },
        },
        {
          key: 'rate',
          label: 'Tỉ trọng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
          tdClass: 'text-right',
        },
      ],
      onLoading: false,
    };
  },
  created() {
    this.fetchDeparment();
    this.jobTitles = cloneDeep(JOB_TITLE.filter((jobTitle) => jobTitle.id));
    this.criterias = ALLOWANCE_CRITERIA.map((criteria) => {
      return {
        ...criteria,
        rate: 0,
      };
    });
  },
  watch: {
    'allowanceConfigModel.criterias': {
      deep: true,
      handler: function (newValue) {
        if (this.allowanceConfigModel.id && this.onLoading) {
          return;
        }
        this.allowanceConfigModel.criteriaItems = newValue.map(
          (criteria, index) => {
            return {
              index: index + 1,
              name: criteria.name,
              criteriaKey: criteria.key,
              rate: 0,
            };
          },
        );
      },
    },
  },
  methods: {
    showModal(configId) {
      this.allowanceConfigModel = {
        id: null,
        name: '',
        departmentId: 1,
        amount: 0,
        jobTitles: [],
        criterias: [],
        criteriaItems: [],
        isActive: 1,
      };
      if (configId) {
        this.onLoading = true;
        this.getInfoById(configId);
      }
      this.$refs['allowance-config-modal'].show();
    },
    hideModal() {
      this.$refs['allowance-config-modal'].hide();
    },
    onSave() {
      const jobTitleIds = this.allowanceConfigModel.jobTitles.map(
        (jobTitle) => jobTitle.id,
      );
      const criteriaKeys = this.allowanceConfigModel.criterias.map(
        (criteria) => criteria.key,
      );
      const payload = {
        name: this.allowanceConfigModel.name.trim(),
        amount: this.allowanceConfigModel.amount,
        departmentId: this.allowanceConfigModel.departmentId,
        jobTitleIds: jobTitleIds.join(','),
        criteriaKeys: criteriaKeys.join(','),
        criteriaItems: this.allowanceConfigModel.criteriaItems,
        isActive: this.allowanceConfigModel.isActive,
      };
      if (!payload.name) {
        return makeToastFaile('Vui lòng nhập tên cấu hình phụ cấp!');
      }
      if (!this.allowanceConfigModel.id) {
        return this.onCreate(payload);
      }
      this.onUpdate(payload);
    },
    onCreate(payload) {
      ApiService.post('allowance-configs', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.hideModal();
            this.$emit('on-fetch-data');
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    onUpdate(payload) {
      const data = {
        ...payload,
        id: this.allowanceConfigModel.id,
      };
      ApiService.put('allowance-configs', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.hideModal();
            this.$emit('on-fetch-data');
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    getInfoById(id) {
      ApiService.get(`allowance-configs/${id}`)
        .then(({ data }) => {
          this.allowanceConfigModel = data.data;
          setTimeout(() => {
            this.onLoading = false;
          }, TIME_TRIGGER);
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
          this.onLoading = false;
        });
    },
    fetchDeparment: async function () {
      this.listDeparment = [];
      ApiService.get('/employees/departments').then((response) => {
        this.listDepartment = response.data.data;
      });
    },
  },
  components: {
    BInputNumber,
    VueMultiSelect,
  },
};
</script>