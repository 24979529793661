var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allowance-config-modal"},[_c('b-modal',{ref:"allowance-config-modal",attrs:{"title":"Cấu hình phụ cấu","hide-footer":"","size":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"required-control"},[_c('label',[_vm._v("Tên cấu hình:")]),_c('b-form-input',{attrs:{"size":"sm","placeholder":"Nhập tên cấu hình"},model:{value:(_vm.allowanceConfigModel.name),callback:function ($$v) {_vm.$set(_vm.allowanceConfigModel, "name", $$v)},expression:"allowanceConfigModel.name"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"required-control"},[_c('label',[_vm._v("Gói phụ cấp:")]),_c('BInputNumber',{staticClass:"text-right",attrs:{"size":"sm","placeholder":"Nhập gói phụ cấp"},model:{value:(_vm.allowanceConfigModel.amount),callback:function ($$v) {_vm.$set(_vm.allowanceConfigModel, "amount", $$v)},expression:"allowanceConfigModel.amount"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"required-control"},[_c('label',[_vm._v("Bộ phận:")]),_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":_vm.listDepartment,"value-field":"id","text-field":"name"},model:{value:(_vm.allowanceConfigModel.departmentId),callback:function ($$v) {_vm.$set(_vm.allowanceConfigModel, "departmentId", $$v)},expression:"allowanceConfigModel.departmentId"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"required-control"},[_c('label',[_vm._v("Trạng thái hoạt động:")]),_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":[
              {
                id: 1,
                name: 'Hoạt động',
              },
              {
                id: 0,
                name: 'Ngừng hoạt động',
              },
            ],"value-field":"id","text-field":"name"},model:{value:(_vm.allowanceConfigModel.isActive),callback:function ($$v) {_vm.$set(_vm.allowanceConfigModel, "isActive", $$v)},expression:"allowanceConfigModel.isActive"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required-control"},[_c('label',[_vm._v("Chức vụ")]),_c('b-input-group',[_c('vue-multi-select',{attrs:{"options":_vm.jobTitles,"placeholder":"Chọn chức vụ","option-label":"name"},model:{value:(_vm.allowanceConfigModel.jobTitles),callback:function ($$v) {_vm.$set(_vm.allowanceConfigModel, "jobTitles", $$v)},expression:"allowanceConfigModel.jobTitles"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required-control"},[_c('label',[_vm._v("Danh sách tiêu chí")]),_c('b-input-group',[_c('vue-multi-select',{attrs:{"options":_vm.criterias,"placeholder":"Chọn tiêu chí","option-label":"name"},model:{value:(_vm.allowanceConfigModel.criterias),callback:function ($$v) {_vm.$set(_vm.allowanceConfigModel, "criterias", $$v)},expression:"allowanceConfigModel.criterias"}})],1)],1),_c('b-table',{attrs:{"items":_vm.allowanceConfigModel.criteriaItems,"fields":_vm.criteriaFields,"hover":"","bordered":""},scopedSlots:_vm._u([{key:"cell(rate)",fn:function(row){return [_c('BInputNumber',{staticClass:"text-right",attrs:{"size":"sm","placeholder":"Nhập tỉ trọng"},model:{value:(row.item.rate),callback:function ($$v) {_vm.$set(row.item, "rate", $$v)},expression:"row.item.rate"}})]}}])})],1)],1),_c('b-button',{staticStyle:{"fontweight":"600","width":"100px"},attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.onSave()}}},[_vm._v("Lưu")]),_c('b-button',{staticStyle:{"margin-left":"10px","font-weight":"600","width":"70px"},attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v("Hủy")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }